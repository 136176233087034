<template>
  <el-dialog title="选择签批人" width="800px"
    v-model="state.showSignDialog"
    @closed="dialogClosed">
    <div class="checkbox-sign">

      <el-form
        ref="ruleFormRef"
        :model="state.ruleForm"
        :rules="rules"
        status-icon>

        <el-form-item prop="signList">
          <el-checkbox-group v-model="state.ruleForm.signList">
            <el-checkbox
              v-for="(item, index) of state.userList"
              :key="index"
              :label="item.id">
              {{item.job}}（{{item.name}}）
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>

    </div>
    <template #footer>
      <span class="file-wrap">
        <el-tag>
          当前文件名:
          <span class="file-title">{{state.title}}</span>
        </el-tag>
      </span>
      <el-button type="primary" @click="enterApplyForSign(ruleFormRef)">申请签批</el-button>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineExpose, defineEmits } from 'vue'
import { leaderList } from '@/api'
import { applyForSign } from '../hooks/useOperation'
const ruleFormRef = ref(null)

const state = reactive({
  showSignDialog: false,
  userList: [],
  ruleForm: {
    signList: []
  },
  title: ''
})

const rules = {
  signList: [
    { required: true, message: '请选择签批人', trigger: 'change' }
  ]
}

let fileId = ''

function show (item) {
  console.log(item)
  state.ruleForm.signList = []
  state.showSignDialog = true
  fileId = item.id
  state.title = item.title
}

function dialogClosed () {
  state.signList = []
  state.title = ''
}

// 申请签批确认按钮
const enterApplyForSign = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      state.showSignDialog = false
      applyForSign({
        id: fileId,
        uid: state.ruleForm.signList
      })
      emit('signFinished')
    } else {
      console.log('error submit!', fields)
    }
  })
}

leaderList().then(res => {
  state.userList = res
})

const emit = defineEmits([
  'signFinished'
])

defineExpose({
  show
})
</script>

<style lang="scss" scoped>
.file-wrap {
  max-width: 650px;
  display: inline-block;
  margin-right: 20px;
  .file-title {
    display: inline-block;
    max-width: 560px;
    color: red;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-break: break-all;
    -webkit-line-clamp: 1;
    position: relative;
    top: 2px;
  }
}
</style>
