<template>
  <el-dialog title="签名文件管理" width="800px"
             v-model="state.showSignDialog"
             @closed="dialogClosed">
    <div class="checkbox-sign">
      <el-scrollbar v-if="state.signList.length">
        <div class="scrollbar-flex-content">
          <div v-for="(item, index) of state.signList" :key="index" class="scrollbar-demo-item">
            <div class="item-top">
              <el-image style="width: 100%;" :src="item.preview" fit="scale-down" />
            </div>
            <el-tag v-if="item.del" type="danger">已删除</el-tag>
            <el-button v-else type="primary" :loading="item.loading"
                       @click="handleDeleteSign(item)">
              删除
            </el-button>
          </div>
        </div>
      </el-scrollbar>
      <div v-else>
        <el-empty description="暂无签名文件" />
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { reactive, defineExpose } from 'vue'
import { getOperationSignList, operationSignDelete } from '@/api'
import { ElMessage } from 'element-plus'
const state = reactive({
  showSignDialog: false,
  signList: []
})
let signId = ''
function show (item) {
  console.log(item)
  state.showSignDialog = true
  signId = item.id
  getOperationSignListApi(item)
}
function dialogClosed () {
  console.log('关闭')
  state.signList = []
}
// 删除
function handleDeleteSign (item) {
  const params = {
    id: signId,
    file: item.file
  }
  console.log('删除', params)
  item.loading = true
  operationSignDelete(params).then(res => {
    console.log('删除成功', res)
    item.del = true
    item.loading = false
  }).catch(err => {
    item.loading = false
    ElMessage.error(err)
  })
}
function getOperationSignListApi (item) {
  getOperationSignList({ id: item.id }).then(res => {
    console.log('签字管理签字图片列表', res)
    state.signList = res
  }).catch(err => {
    ElMessage.error(err)
  })
}
defineExpose({
  show
})
</script>
<style lang="scss" scoped>
.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin: 10px;
  .item-top {
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 4px;
    background: var(--el-color-danger-light-9);
    overflow: hidden;
  }
}
</style>
