import { ElMessage, ElMessageBox } from 'element-plus'
import { fileFiled, fileApply, fileView } from '@/api'
import { baseApi } from '@/api/host'
import { fileDetailApi } from '@/utils'

// 预览
export function previewPdf (item) {
  fileDetailApi(item.id).then(() => {
    fileView({ id: item.id }).then(res => {
      console.log('预览', res)
    })
  })
}

// 申请签批
export function applyForSign (params, callback) {
  console.log('申请签批', params)
  fileApply(params).then(res => {
    console.log('申请签批接口', res)
    ElMessage({
      type: 'success',
      message: '申请签批成功'
    })
    callback && callback()
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err.toString()
    })
  })
}

// 下载
export function downloadFile (item) {
  console.log('下载ID', item.id)
  fileDetailApi(item.id).then(() => {
    ElMessageBox.confirm(
      `确认下载【${item.title}】吗?`,
      '提示',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      window.open(`${baseApi}/api/cms/file/download?id=${item.id}`)
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '取消下载'
      })
    })
  })
}

// 归档
export function returnFile (item, callback) {
  console.log('归档ID', item.id)
  fileDetailApi(item.id).then(() => {
    ElMessageBox.prompt(
      '确认归档吗?',
      '提示',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入备注',
        type: 'warning',
        // inputPattern: /^.+$/,
        inputErrorMessage: '备注不能为空'
      }
    ).then(content => {
      fileFiled({ id: item.id, archive_remark: content?.value || '' }).then(res => {
        console.log('归档', res)
        ElMessage({
          type: 'success',
          message: '归档成功'
        })
        callback && callback()
      }).catch(err => {
        ElMessage({
          type: 'error',
          message: err.toString()
        })
      })
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '取消归档'
      })
    })
  })
}
