<template>
  <el-dialog title="替换文件" width="600" v-model="state.showReplaceDialog">
    <el-row type="flex" style="margin-bottom: 10px;" v-if="roles !== '4'">
      <el-col :span="24" align="right">
        <div class="progress-wrap">
          <el-progress v-if="state.loading" style="width: 120px;"
                       :percentage="30" color="var(--el-color-primary)"
                       :indeterminate="true"
                       :show-text="false"
          />
          <span v-if="state.loading" class="timer">耗时：{{formatSeconds(state.timerNum)}}</span>
          <el-upload
            ref="uploadRef"
            :disabled="state.loading"
            accept=".pdf, .ofd"
            :action="baseApi + '/api/cms/file/operation/upload'"
            :headers="{
            Authorization: getCookie('token') || ''
          }"
            :data="{id: signId}"
            :show-file-list="false"
            :on-change="handleChange"
            :on-progress="handleProgress"
            :on-success="handleSuccess"
            :on-error="handleError">
            <el-tag v-if="state.loading" type="danger" style="margin-right: 10px;">
              文件上传中请勿刷新页面或中断网络
            </el-tag>
            <el-tooltip
              :visible="state.tooltipShow"
              effect="dark"
              content="上传文件过大，转码时间会较长"
              placement="bottom-end">
              <el-button v-if="!state.loading" type="primary" icon="Upload">上传文件</el-button>
              <el-button v-else type="primary" :loading="state.loading">上传中...</el-button>
            </el-tooltip>
          </el-upload>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineEmits, defineExpose } from 'vue'
import { getCookie, formatSeconds } from '@/utils'
import { baseApi } from '@/api/host'
import { ElMessage } from 'element-plus'
const roles = getCookie('roles')
let clock = 0
const state = reactive({
  showReplaceDialog: false,
  loading: false,
  tooltipShow: false,
  timerNum: 0
})
const signId = ref('13')
function show (item) {
  console.log('接收参数', item)
  signId.value = item.id
  state.showReplaceDialog = true
}
function handleChange (data) {
  if (data.size > 10000000) {
    state.tooltipShow = true
  } else {
    state.tooltipShow = false
  }
}
function handleProgress () {
  if (!clock) {
    state.loading = true
    clock = setInterval(() => {
      state.timerNum += 1
    }, 1000)
  }
}
function handleSuccess (data) {
  console.log('Success', data)
  if (data.code === 10000) {
    clearClock()
    ElMessage({
      type: 'success',
      message: '替换成功'
    })
    state.showReplaceDialog = false
    emit('uploadFinished')
  } else {
    handleError()
  }
}
function handleError () {
  clearClock()
  ElMessage({
    type: 'error',
    message: '上传失败'
  })
}
function clearClock () {
  state.loading = false
  state.tooltipShow = false
  clearInterval(clock)
  clock = 0
  state.timerNum = 0
}
const emit = defineEmits([
  'uploadFinished'
])
defineExpose({
  show
})
</script>
<style lang="scss" scoped>
.progress-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .timer {
    padding: 0 10px;
    font-size: 12px;
    color: #606266;
  }
}
</style>
