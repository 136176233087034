<template>
  <el-row v-if="roles !== '5'">
    <el-col :span="10">
      <el-form ref="ruleFormRef"
               :inline="true" :model="state.formData"
               :rules="rules"
               @keyup.enter="onSubmit">
        <el-form-item v-if="state.departmentId === 1" prop="value">
          <el-input
            v-model="state.formData.value"
            :placeholder="searchPlaceholder()"
            :disabled="!state.formData.type">
            <template #prepend>
              <el-select v-model="state.formData.type" placeholder="选择类型" style="width: 100px">
                <!-- <el-option label="全部" value="" /> -->
                <el-option label="来文编号" value="file_no" />
                <el-option label="文件名" value="title" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit(ruleFormRef)">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="14">
      <!--      <Upload @uploadFinished="uploadFinished"></Upload>-->
    </el-col>
  </el-row>
  <ProcessingTwo ref="processingTwoRef"></ProcessingTwo>
</template>
<script setup>
import { ref, reactive, watchEffect } from 'vue'
// import Upload from './components/Upload.vue'
import ProcessingTwo from './components/ProcessingTwo.vue'
import { getCookie } from '@/utils'
import { useStore } from 'vuex'
const store = useStore()
// const pendingRef = ref(null)
const processingTwoRef = ref(null)
const roles = getCookie('roles')
const departmentId = Number(getCookie('departmentId')) || 2
const ruleFormRef = ref()
if (roles === '4' || roles === '5') {
  store.commit('changeSignName', '4')
} else {
  store.commit('changeSignName', '1')
}
const state = reactive({
  departmentId,
  formData: {
    type: 'file_no',
    value: ''
  }
})
const rules = {
  value: [
    { required: true, message: '搜索条件不能为空', trigger: 'blur' }
  ]
}
watchEffect(() => {
  if (!state.formData.type) {
    state.formData.value = ''
  }
})
async function onSubmit (formEl) {
  console.log('submit!', state.formData)
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      const params = { page: 1 }
      if (state.formData?.type) {
        params[state.formData.type] = state.formData.value
      }
      processingTwoRef.value.getOperationListApi(params)
    } else {
      console.log('error submit!', fields)
    }
  })
}
function searchPlaceholder () {
  let placeholder = '搜索查看全部'
  if (state.formData.type) {
    placeholder = state.formData.type === 'file_no' ? '请输入来文编号' : '请输入文件名'
  }
  return placeholder
}
// function uploadFinished () {
//   console.log('上传完成')
//   store.commit('changeSignName', '1')
//   pendingRef.value.currentPageChange()
// }
</script>
<style lang="scss" scoped>
.mark1 {
  position: absolute;
  top: 57px;
  left: 90px;
  z-index: 9;
}
.mark2 {
  position: absolute;
  top: 57px;
  left: 174px;
  z-index: 9;
}
</style>
