<template>
  <el-table :data="state.tableData" stripe border style="width: 100%" v-loading="state.loading">
    <el-table-column label="序号" align="center" type="index" width="60" />
    <el-table-column label="来文编号" prop="file_no" v-if="state?.tableData[0]?.template_id === 1" />
    <el-table-column label="文件名" prop="title" width="300" />
    <el-table-column label="当前审批人">
      <template #default="scope">
        <div style="display: flex; align-items: center" v-if="scope.row.admin">
          <el-icon><User /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.admin.realname }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="紧急程度" v-if="state?.tableData[0]?.template_id === 1">
      <template #default="scope">
        <el-tag v-if="scope.row.emergency" :type="scope.row.emergency !== '一般' ? 'danger' : 'info'">{{ scope.row.emergency }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="审批状态" align="center" width="82">
      <template #default="scope">
        <el-tag
          type="danger"
          v-if="scope.row.approval_status === '未完成'">
          {{scope.row.approval_status}}
        </el-tag>
        <el-tag
          type="success"
          v-if="scope.row.approval_status === '已完成'">
          {{scope.row.approval_status}}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column label="上传时间">
      <template #default="scope">
        <div v-if="scope.row.approval_status === '已完成' && scope.row.create_time"
             style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </div>
        <el-icon v-else><Minus /></el-icon>
      </template>
    </el-table-column>
    <el-table-column label="完成时间">
      <template #default="scope">
        <div v-if="scope.row.approval_status === '已完成' && scope.row.finish_time"
             style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.finish_time }}</span>
        </div>
        <el-icon v-else><Minus /></el-icon>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="330">
      <template #default="scope">
        <el-button size="small" type="success"
                   @click="handleFilePreview(scope.row)">
          预览
        </el-button>
        <el-button size="small" type="warning"
                   @click="handleManageSign(scope.row)">
          管理签字
        </el-button>
        <el-button size="small" type="danger"
                   @click="handleReplaceFile(scope.row)">
          替换文件
        </el-button>
        <el-popconfirm
          title="确定将该文件状态变更为处理中吗？"
          @confirm="handleChangeStatus(scope.row)">
          <template #reference>
            <el-button size="small" type="info">
              修改状态
            </el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-if="state.total"
    style="margin-top: 15px;"
    small
    background
    layout="prev, pager, next"
    :total="state.total"
    :current-page="state.pageOptions.page"
    :page-size="state.pageOptions.page_size"
    @current-change="currentPageChange"
  />
  <ManageSign ref="ManageSignRef" />
  <ReplaceUpload ref="ReplaceUploadRef" @uploadFinished="uploadFinished"></ReplaceUpload>
</template>
<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import ReplaceUpload from './replaceUpload.vue'
import ManageSign from './ManageSign.vue'
import { changeOperationStatus, getOperationList } from '@/api'
import { fileDetailApi } from '@/utils'
const ManageSignRef = ref()
const ReplaceUploadRef = ref()
const state = reactive({
  loading: false,
  pageOptions: {
    page: 1,
    page_size: 10
  },
  total: 0,
  tableData: []
})
// 预览
function handleFilePreview (item) {
  fileDetailApi(item.id).then(() => {
    window.open(`${item.source_pdf}?t=${new Date().getTime()}`)
  })
}
// 管理签字
function handleManageSign (item) {
  console.log('管理签字', item)
  ManageSignRef.value.show(item)
}
// 替换文件
function handleReplaceFile (item) {
  console.log('撤回', item)
  fileDetailApi(item.id).then(() => {
    ElMessageBox.confirm(
      `确定要替换【${item.title}】原文件吗？请谨慎操作！`,
      '温馨提示',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      ReplaceUploadRef.value.show(item)
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消操作'
      })
    })
  })
}
function handleChangeStatus (item) {
  console.log('修改状态', item.id)
  changeOperationStatus({ id: item.id }).then(res => {
    ElMessage.success('修改成功')
  }).catch(err => {
    ElMessage.error(err || '修改失败')
  })
}
// 页码改变
function currentPageChange (page) {
  state.pageOptions.page = page || 1
  getOperationListApi()
}
// 文件处理中列表接口
function getOperationListApi (data) {
  const params = {
    ...state.pageOptions,
    ...data
  }
  state.loading = true
  getOperationList(params).then(res => {
    console.log('签字管理特殊搜索列表', res)
    state.tableData = res.data
    state.total = res.total
    state.loading = false
  })
}
function uploadFinished (data) {
  console.log('上传完成', data)
}
defineExpose({
  getOperationListApi
})
</script>
<style lang="scss" scoped>
</style>
