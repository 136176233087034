<template>
  <el-table :data="state.tableData" border style="width: 100%" v-loading="state.loading" :row-class-name="tableRowClassName">
    <el-table-column label="序号" align="center" type="index" width="60" />
    <el-table-column label="来文编号" prop="file_no" v-if="state?.tableData[0]?.template_id === 1" />
    <el-table-column label="文件名" prop="title" width="300" />

    <el-table-column label="当前审批人">
      <template #default="scope">
        <div style="display: flex; align-items: center" v-if="scope.row.admin">
          <el-icon><User /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.admin.realname }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="紧急程度" v-if="state?.tableData[0]?.template_id === 1">
      <template #default="scope">
        <el-tag v-if="scope.row.emergency" :type="scope.row.emergency !== '一般' ? 'danger' : 'info'">{{ scope.row.emergency }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="审批状态" align="center" width="82">
      <template #default="scope">
        <el-tag
          type="danger"
          v-if="scope.row.approval_status === '未完成'">
          {{scope.row.approval_status}}
        </el-tag>
        <el-tag
          type="success"
          v-if="scope.row.approval_status === '已完成'">
          {{scope.row.approval_status}}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column label="完成时间">
      <template #default="scope">
        <div v-if="scope.row.approval_status === '已完成' && scope.row.finish_time"
          style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.finish_time }}</span>
        </div>
        <el-icon v-else><Minus /></el-icon>
      </template>
    </el-table-column>

    <el-table-column label="操作" :width="(roles === '4' || roles === '5') ? '73' : '500'">
      <template #default="scope">
        <el-badge :is-dot="!scope.row.inspectionRead">
          <el-button
            size="small"
            type="success"
            @click="handleFilePreview(scope.row)">
            预览
          </el-button>
        </el-badge>
        <el-button
          v-if="roles !== '4' && roles !== '5'"
          size="small"
          @click="handleEdit(scope.row)">
          编辑
        </el-button>
        <el-button
          v-if="roles !== '4' && roles !== '5'"
          size="small"
          type="primary"
          @click="handleApplyForSign(scope.row)">
          申请签批
        </el-button>
        <el-button
          v-if="roles !== '4' && roles !== '5'"
          size="small"
          type="info"
          @click="downloadFile(scope.row)">
          下载
        </el-button>
        <el-button
          v-if="roles !== '4' && roles !== '5'"
          size="small"
          type="warning"
          @click="handleReturnFile(scope.row)">
          归档
        </el-button>
        <el-button
          v-if="roles !== '4' && roles !== '5'"
          size="small"
          type="danger"
          icon="DocumentDelete"
          @click="handleWithdraw(scope.row)">
          撤回
        </el-button>
        <el-button
          v-if="roles !== '4' && roles !== '5'"
          size="small"
          @click="handleViewProgress(scope.row)">
          进度查看
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    v-if="state.total"
    style="margin-top: 15px;"
    small
    background
    layout="prev, pager, next"
    :total="state.total"
    :current-page="state.pageOptions.page"
    :page-size="state.pageOptions.page_size"
    @current-change="currentPageChange"
  />

  <SignDialog ref="signDialogRef" @signFinished="signFinished"></SignDialog>
  <ViewProgress  ref="viewProgressRef"></ViewProgress>
  <FilePreview ref="filePreviewRef"></FilePreview>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { returnFile, downloadFile } from '../hooks/useOperation'
import SignDialog from './SignDialog.vue'
import ViewProgress from '@/components/ViewProgress.vue'
import FilePreview from '@/components/FilePreview.vue'
import { getFileSecretaryList, getFileInspectionProcessingList, fileCancel, inspectionRead } from '@/api'
import { setSession, fileDetailApi, getCookie } from '@/utils'
import { useRouter } from 'vue-router'
const router = useRouter()
const signDialogRef = ref(null)
const viewProgressRef = ref(null)
const filePreviewRef = ref(null)
const roles = getCookie('roles')

const state = reactive({
  loading: false,
  pageOptions: {
    page: 1,
    page_size: 10
  },
  total: 0,
  tableData: []
})

function tableRowClassName ({ row, rowIndex }) {
  return row.inspectionRead ? 'read' : ''
}

// 预览
function handleFilePreview (item) {
  fileDetailApi(item.id).then(() => {
    window.open(`${item.source_pdf}?t=${new Date().getTime()}`)
  })
  inspectionRead({ document_id: item.id }).then(res => {
    item.inspectionRead = true
  })
  // filePreviewRef.value.show(item)
}

// 编辑
function handleEdit (item) {
  console.log('编辑', item)
  fileDetailApi(item.id).then(() => {
    setSession('editData', { ...item, disabled: true, merge_sign: 1 })
    router.push({
      path: '/signFileEdit',
      query: {
        id: item.id,
        title: item.title
      }
    })
  })
}

// 申请签批
function handleApplyForSign (item) {
  fileDetailApi(item.id).then(() => {
    signDialogRef.value.show(item)
  })
}

// 签批完成
function signFinished () {
  setTimeout(() => {
    getFileSecretaryListApi()
  }, 1000)
}

// 归档
function handleReturnFile (item) {
  returnFile(item, () => {
    getFileSecretaryListApi()
  })
}

// 撤回
function handleWithdraw (item) {
  console.log('撤回', item)
  fileDetailApi(item.id).then(() => {
    ElMessageBox.confirm(
      `如果撤回【${item.title}】，该文件将作废。您可以重新上传，再次发起签批！`,
      '温馨提示',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      fileCancel({ id: item.id }).then(res => {
        console.log('撤回成功', res)
        getFileSecretaryListApi()
        ElMessage({
          type: 'success',
          message: '撤回成功！'
        })
      })
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消撤回'
      })
    })
  })
}

// 进度查看
function handleViewProgress (item) {
  console.log('进度查看', item)
  fileDetailApi(item.id).then(() => {
    viewProgressRef.value.show(item)
  })
}

// 页码改变
function currentPageChange (page) {
  state.pageOptions.page = page || 1
  getFileSecretaryListApi()
}

// 文件处理中列表接口
function getFileSecretaryListApi (data) {
  state.pageOptions = data ? Object.assign(state.pageOptions, data) : state.pageOptions
  state.loading = true
  if (roles === '4') {
    getFileSecretaryList(state.pageOptions).then(res => {
      console.log('秘书文件处理中列表', res)
      state.tableData = res.data
      state.total = res.total
      state.loading = false
    })
  }
  if (roles === '5') {
    getFileInspectionProcessingList(state.pageOptions).then(res => {
      console.log('特殊文件处理中列表', res)
      state.tableData = res.data
      state.total = res.total
      state.loading = false
    })
  }
}
getFileSecretaryListApi()

defineExpose({
  getFileSecretaryListApi
})
</script>

<style lang="scss">
.read {
  // --el-table-tr-bg-color: var(--el-color-info-light-9);
  opacity: 0.5;
}
.el-table .cell {
  overflow: visible;
}
</style>
