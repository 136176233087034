<template>
  <div v-if="roles === '6'">
    <FileStepTwo />
  </div>
  <div v-else-if="roles !== '5'">
    <FileStepOne />
  </div>
</template>

<script setup>
import { getCookie } from '@/utils'
import FileStepOne from './FileStepOne.vue'
import FileStepTwo from './FileStepTwo.vue'
const roles = getCookie('roles')

</script>

<style lang="scss" scoped>
</style>
