<template>
  <el-row v-if="roles !== '5'">
    <el-col :span="10">
      <el-form
        :inline="true" :model="state.formData"
        @keyup.enter="onSubmit">
        <el-form-item v-if="state.departmentId === 1">
          <el-input
            v-model="state.formData.value"
            :placeholder="searchPlaceholder()"
            :disabled="!state.formData.type">
            <template #prepend>
              <el-select v-model="state.formData.type" placeholder="选择类型" style="width: 100px">
                <!-- <el-option label="全部" value="" /> -->
                <el-option label="来文编号" value="file_no" />
                <el-option label="文件名" value="title" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="文件名" v-else>
          <el-input
            v-model="state.formData.value"
            placeholder="请输入文件名">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="14">
      <Upload @uploadFinished="uploadFinished"></Upload>
    </el-col>
  </el-row>
  <el-badge
    :class="(roles === '4' || roles === '5') ? 'mark1' : 'mark2'"
    v-if="store.state.noticeCount"
    :value="store.state.noticeCount"
  />
  <el-tabs type="border-card" v-model="store.state.signName" @tab-click="tabClick">
    <el-tab-pane label="待处理" name="1" v-if="roles !== '4' && roles !== '5'">
      <Pending ref="pendingRef"></Pending>
    </el-tab-pane>
    <el-tab-pane label="处理中" name="2" v-if="roles !== '4' && roles !== '5'">
      <Processing ref="processingRef"></Processing>
    </el-tab-pane>
    <el-tab-pane label="已撤回" name="3" v-if="roles !== '4' && roles !== '5'">
      <Canceling ref="cancelingRef"></Canceling>
    </el-tab-pane>
    <!-- 秘书处理中列表 -->
    <el-tab-pane :label="roles === '4' ? '处理中' : '签批列表'" name="4" v-if="roles === '4' || roles === '5'">
      <Secretary ref="secretaryRef"></Secretary>
    </el-tab-pane>
  </el-tabs>
</template>
<script setup>
import { ref, reactive, watchEffect } from 'vue'
import Upload from './components/Upload.vue'
import Pending from './components/Pending.vue'
import Processing from './components/Processing.vue'
import Canceling from './components/Canceling.vue'
import Secretary from './components/Secretary.vue'
import { getCookie } from '@/utils'
import { useStore } from 'vuex'
const store = useStore()
const pendingRef = ref(null)
const processingRef = ref(null)
const cancelingRef = ref(null)
const secretaryRef = ref(null)
const roles = getCookie('roles')
const departmentId = Number(getCookie('departmentId')) || 2
if (roles === '4' || roles === '5') {
  store.commit('changeSignName', '4')
} else {
  store.commit('changeSignName', '1')
}
const state = reactive({
  departmentId,
  formData: {
    type: 'file_no',
    value: ''
  }
})
let tabName = (roles === '4' || roles === '5') ? '4' : '1'
watchEffect(() => {
  if (!state.formData.type) {
    console.log('???', state.formData.value)
    state.formData.value = ''
  }
})
function onSubmit () {
  console.log('submit!', state.formData)
  const params = { page: 1 }
  if (state.formData?.type) {
    params[state.formData.type] = state.formData.value
  }
  console.log(params)
  if (tabName === '1') {
    pendingRef.value.getFilePendingListApi(params)
  }
  if (tabName === '2') {
    processingRef.value.getFileProcessingListApi(params)
  }
  if (tabName === '3') {
    cancelingRef.value.getFileCancelingListApi(params)
  }
  if (tabName === '4') {
    secretaryRef.value.getFileSecretaryListApi(params)
  }
}
function searchPlaceholder () {
  let placeholder = '搜索查看全部'
  if (state.formData.type) {
    placeholder = state.formData.type === 'file_no' ? '请输入来文编号' : '请输入文件名'
  }
  return placeholder
}
function tabClick (tab) {
  state.formData = {
    type: 'file_no',
    value: ''
  }
  tabName = tab.props.name
  if (tabName === '2') {
    processingRef.value.getFileProcessingListApi()
  }
  if (tabName === '3') {
    cancelingRef.value.getFileCancelingListApi()
  }
  if (tabName === '4') {
    secretaryRef.value.getFileSecretaryListApi()
  }
}
function uploadFinished () {
  console.log('上传完成')
  store.commit('changeSignName', '1')
  pendingRef.value.currentPageChange()
}
</script>
<style lang="scss" scoped>
.mark1 {
  position: absolute;
  top: 57px;
  left: 90px;
  z-index: 9;
}
.mark2 {
  position: absolute;
  top: 57px;
  left: 174px;
  z-index: 9;
}
</style>
