<template>
  <el-table :data="state.tableData" stripe border style="width: 100%" v-loading="state.loading">
    <el-table-column label="序号" align="center" type="index" width="60" />
    <el-table-column label="来文编号" prop="file_no" v-if="state?.tableData[0]?.template_id === 1" />
    <el-table-column label="文件名" prop="title" width="300" />

    <el-table-column label="当前审批人">
      <template #default="scope">
        <div style="display: flex; align-items: center" v-if="scope.row.admin">
          <el-icon><User /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.admin.realname }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="紧急程度" v-if="state?.tableData[0]?.template_id === 1">
      <template #default="scope">
        <el-tag v-if="scope.row.emergency" :type="scope.row.emergency !== '一般' ? 'danger' : 'info'">{{ scope.row.emergency }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="撤回时间">
      <template #default="scope">
        <div v-if="scope.row.update_time"
          style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.update_time }}</span>
        </div>
        <el-icon v-else><Minus /></el-icon>
      </template>
    </el-table-column>

    <el-table-column label="操作" width="412">
      <template #default="scope">
        <el-button
          size="small"
          type="success"
          @click="handleFilePreview(scope.row)">
          预览
        </el-button>
        <el-button
          size="small"
          @click="handleEdit(scope.row)">
          编辑
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleApplyForSign(scope.row)">
          申请签批
        </el-button>
        <el-button
          size="small"
          type="info"
          @click="downloadFile(scope.row)">
          下载
        </el-button>
        <el-button
          size="small"
          type="danger"
          icon="Delete"
          @click="handleDelete(scope.row)">
        </el-button>
        <el-button
          size="small"
          @click="handleViewProgress(scope.row)">
          进度查看
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    v-if="state.total"
    style="margin-top: 15px;"
    small
    background
    layout="prev, pager, next"
    :total="state.total"
    :current-page="state.pageOptions.page"
    :page-size="state.pageOptions.page_size"
    @current-change="currentPageChange"
  />

  <SignDialog ref="signDialogRef" @signFinished="signFinished"></SignDialog>
  <ViewProgress  ref="viewProgressRef"></ViewProgress>
  <FilePreview ref="filePreviewRef"></FilePreview>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { downloadFile } from '../hooks/useOperation'
import SignDialog from './SignDialog.vue'
import ViewProgress from '@/components/ViewProgress.vue'
import FilePreview from '@/components/FilePreview.vue'
import { getFileCancelingList, fileDelete } from '@/api'
import { setSession, fileDetailApi } from '@/utils'
import { useRouter } from 'vue-router'
const router = useRouter()
const signDialogRef = ref(null)
const viewProgressRef = ref(null)
const filePreviewRef = ref(null)

const state = reactive({
  loading: false,
  pageOptions: {
    page: 1,
    page_size: 10
  },
  total: 0,
  tableData: []
})

// 预览
function handleFilePreview (item) {
  fileDetailApi(item.id).then(() => {
    window.open(`${item.source_pdf}?t=${new Date().getTime()}`)
  })
  // filePreviewRef.value.show(item)
}

// 编辑
function handleEdit (item) {
  console.log('编辑', item)
  fileDetailApi(item.id).then(() => {
    setSession('editData', { ...item, merge_sign: 1 })
    router.push({
      path: '/signFileEdit',
      query: {
        id: item.id,
        title: item.title
      }
    })
  })
}

// 申请签批
function handleApplyForSign (item) {
  fileDetailApi(item.id).then(() => {
    signDialogRef.value.show(item)
  })
}

// 签批完成
function signFinished () {
  setTimeout(() => {
    getFileCancelingListApi()
  }, 1000)
}

// 删除
function handleDelete (item) {
  ElMessageBox.confirm(
    '确认删除吗?',
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    fileDeleteApi({ id: item.id })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除'
    })
  })
}

// 进度查看
function handleViewProgress (item) {
  console.log('进度查看', item)
  fileDetailApi(item.id).then(() => {
    viewProgressRef.value.show(item)
  })
}

// 页码改变
function currentPageChange (page) {
  state.pageOptions.page = page || 1
  getFileCancelingListApi()
}

// 文件处理中列表接口
function getFileCancelingListApi (data) {
  state.pageOptions = data ? Object.assign(state.pageOptions, data) : state.pageOptions
  state.loading = true
  getFileCancelingList(state.pageOptions).then(res => {
    console.log('文件已撤回列表', res)
    state.tableData = res.data
    state.total = res.total
    state.loading = false
  })
}
getFileCancelingListApi()

// 文件删除接口
function fileDeleteApi (params) {
  fileDelete(params).then(res => {
    console.log('文件删除', res)
    ElMessage({
      type: 'success',
      message: '删除成功'
    })
    getFileCancelingListApi()
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err.toString()
    })
  })
}

defineExpose({
  getFileCancelingListApi
})
</script>

<style lang="scss" scoped>

</style>
